.poster {
  width: 100%;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  text-decoration: none;

  &__img {
    width: 100%;
    border-radius: 20px;
  }

  &__title {
    margin-top: 8px;
    margin-left: 16px;
  }

  &__duration {
    margin-top: 8px;
    margin-left: 16px;
    padding-bottom: 24px;
  }
}
