@import "src/assets/styles/colors";

.homePage {
  padding-top: 140px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 80px;
  }

  .introductoryWords {
    padding-bottom: 28px;

    &__title {
      margin-bottom: 16px;
    }

    &__description {
      max-width: 570px;
    }
  }

  .contentGroup {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &__top {
      display: flex;
      margin-bottom: 16px;
      justify-content: space-between;
      align-items: center;

      &__title {
        position: relative;
        margin-left: 20px;

        &:after {
          content: '';
          position: absolute;
          background: $color-brown;
          width: 10px;
          height: 10px;
          border-radius: 50px;
          left: -20px;
          top: 10px;
        }
      }

      &__all {
        color: $color-black;
        white-space: nowrap;
      }
    }
  }

  .tariffNotPaid {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &__descriptions {
      width: 50%;

      @media (max-width: 768px) {
        margin-top: 24px;
        width: 100%;
        order: 1;
      }

      &__title {
        margin-bottom: 16px;
      }

      &__description {
        margin-bottom: 24px;
      }
    }

    &__imgContainer {
      width: 40%;

      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &__img {
        max-width: 342px;
        max-height: 441px;

        @media (max-width: 768px) {
          width: 137px;
          height: 177px;
        }
      }
    }
  }
}
