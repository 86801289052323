@import "../../../assets/styles/colors";

.userJourney {
  position: relative;
  height: 100%;

  .link {
    color: $color-brown;

    &__forgotPassword {
      margin-top: 16px;
    }
  }

  &__back {
    color: $color-black;
    position: absolute;
    top: 120px;
    padding-left: 24px;
    cursor: pointer;

    @media (max-width: 768px) {
      top: 80px;
    }

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      background: url("../../images/icons/arrow-left_black.svg");
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-top: 180px;
    padding-bottom: 120px;
    height: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-top: 120px;
      padding-bottom: 60px;
    }

    .infoText {
      height: max-content;
      width: 45%;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        background: url("../../images/icons/arrow-down.svg") no-repeat;
        width: 170px;
        height: 140px;
        right: 0;
        bottom: -170px;
        transform: rotate(-105deg);

        @media (max-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      &__h2 {
        margin-bottom: 24px;
      }

      &__p {
        @media (max-width: 768px) {
          margin-bottom: 40px;
        }
      }
    }

    .formContainer {
      width: 45%;
      display: flex;
      flex-direction: column;
      height: max-content;

      @media (max-width: 768px) {
        width: 100%;
      }

      &__chooseTariff {
        padding-top: 16px;
        border-bottom: solid #8E6450 1px;

        &__checkbox {
          margin-bottom: 16px;
        }
      }

      .totalCost {
        margin-top: 16px;
        color: $color-brown;
      }

      .sitePolicy {
        margin-top: 24px;

        &__checkbox {
          &:nth-child(1) {
            margin-bottom: 16px;
          }
        }
      }

      &__btn {
        margin-top: 24px;
      }
    }
  }
}

.resetPasswordSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;

  &__h2 {
    margin-top: 32px;
  }

  &__p {
    max-width: 400px;
    margin-top: 8px;
  }

  &__btn {
    margin-top: 24px;
  }
}
