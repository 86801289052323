@import "../../../assets/styles/colors";

.header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  z-index: 1000;

  @media (max-width: 768px) {
    height: 60px;
  }

  &_active {
    position: fixed;
  }

  .logoContainer {
    z-index: 1000;
    .logo {
      width: 70px;
      height: 70px;
      z-index: 1000;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    z-index: 1000;

    &__item {
      cursor: pointer;
      text-decoration: none;
      margin-right: 24px;
      color: $color-light-brown;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .navMobile {
    background: $color-bg;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: -9999px;
    transition: left 0.4s;

    &.active {
      left: 0;
    }

    .navLinks {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__item {
        cursor: pointer;
        display: none;
        text-decoration: none;
        margin-bottom: 32px;
        color: $color-black;

        @media (max-width: 768px) {
          display: flex;
        }
      }
    }

    .footer {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }
}

.menuButton {
  margin-left: 16px;
  cursor: pointer;
  display: none;
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 10;
  transition: transform 0.4s ease;

  @media (max-width: 768px) {
    display: block;
  }

  &.active {
    .menuButtonBurger {
      background-color: $color-black;
      transform: translate(0, 0) rotate(45deg);

      &:before,
      &:after {
        background-color: $color-black;
        transform: translate(0, 0) rotate(90deg);
      }
    }
  }

  .menuButtonBurger {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transition: transform 0.4s ease;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%; /* Ширина ліній */
      height: 3px; /* Висота ліній */
      background-color: #fff; /* Колір ліній */
      transition: transform 0.4s ease;
    }

    &:before {
      transform: translateY(-8px);
    }

    &:after {
      transform: translateY(8px);
    }
  }
}
