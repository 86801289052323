@import '../styles/colors';

.input {
  padding: 22px 0;
  border: none;
  border-bottom: solid .5px $color-black;
  color: $color-black;
  background: $color-light-brown;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  &__transparent {
    background: transparent;
    padding: 16px 0;
  }
}
