@import "../../../assets/styles/colors";
@import "../../../assets/styles/fonts";

.tableContainer {
  overflow-x: auto;
  border-radius: 12px;
  border: 1px solid #ddd;
  flex: 1;
  background: $color-white;

  .table {
    overflow-x: auto;
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;

    .thead {
      .th {
        background-color: $color-light;
        font-weight: $weight-bold;
      }
    }

    .th,
    .td {
      text-align: left;
      padding: 18px;
      border-bottom: 1px solid #ddd;

      &__status {
        font-weight: $weight-bold;

        &_red {
          color: $color-red;
        }
        &_green {
          color: $color-green;
        }
        &_yellow {
          color: #DDA20D;
        }
      }
    }

    .tr:hover {
      background-color: #f5f5f5;
    }
  }
}
