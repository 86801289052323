@import "colors";

* {
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  min-height: 100vh;
  line-height: 1;
}

#root {
  min-height: 100vh;
}

.container {
  padding-left: 135px;
  padding-right: 135px;

  @media (max-width: 1440px){
    padding-left: 80px;
    padding-right: 80px;
  }

  @media (max-width: 1024px){
    padding-left: 24px;
    padding-right: 24px;
  }
}

.container-left {
  padding-left: 135px;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.modal {
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
}
