.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 768px){
    padding: 0 48px;
  }

  .img {
    width: 100%;
    max-width: 386px;
  }

  .text {
    margin-top: 32px;
    margin-bottom: 24px;
    max-width: 640px;
    text-align: center;
  }
}
