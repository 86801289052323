@import '../../../../../assets/styles/colors';


.content {
  border-bottom: solid $color-border 1px;

  .questionTitle {
    cursor: pointer;
    position: relative;
    padding: 24px 40px 24px 0;
  }

  .answerText {
    margin-bottom: 24px;
  }

  .toggle {
    position: absolute;
    width: 20px;
    height: 2px;
    background: $color-black;
    top: 50%;
    right: 0;

    @media (max-width: 768px) {
      right: 10px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 20px;
      height: 2px;
      background: $color-black;
      transform: rotate(90deg);
      transition: transform .1s;
    }

    &.active {
      &:after {
        transform: rotate(0);
      }
    }
  }
}

