.postersList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  column-gap: 24px;
  row-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1,minmax(0,1fr));
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
