/* Стилі контейнера */
.radioContainer {
  width: max-content;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
}

/* Приховуємо стандартну радіо-кнопку браузера */
.radioContainer .radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Створюємо кастомну радіо-кнопку */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: solid #8E6450 1px;
  border-radius: 50%;
}

/* Змінюємо фон при виборі */
.radioContainer .radio:checked ~ .checkmark {
  background-color: #8E6450;
}

/* Створюємо індикатор вибору */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Показуємо індикатор при виборі */
.radioContainer .radio:checked ~ .checkmark:after {
  display: block;
}

/* Стилізуємо індикатор */
.radioContainer .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
