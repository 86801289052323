@import '../../../assets/styles/colors';

.contentGroupPage {
  padding-top: 140px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 80px;
  }

  &__link{
    text-decoration: none;
    color: $color-black;
  }

  &__title {
    margin-bottom: 16px;
    margin-left: 32px;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      background: url("../../images/icons/arrow-left.svg");
      position: absolute;
      left: -32px;
      width: 24px;
      height: 24px;
      top: 2px;
    }
  }
}
