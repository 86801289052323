@import '../../../../../assets/styles/colors';

.form {
  display: flex;
  flex-direction: column;
}


.btn {
  margin-top: 24px;
  width: 135px;
}

.submitted {
  margin-top: 20px;
  text-align: center;
}
