@import '../../../../../assets/styles/colors';

.tariffsSliderContainer {
  .tariffsSwitch {
    margin: 8px auto;
    border: solid 1px $color-brown;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    max-width: 800px;
    flex-wrap: wrap;
    width: max-content;

    @media (max-width: 768px) {
      width: auto;
    }

    &__item {
      cursor: pointer;
      border-radius: 60px;
      color: $color-brown;
      padding: 8px 16px;
      margin: 8px;

      &_active {
        background: $color-brown;
        font-weight: 700;
        color: $color-white;
      }
    }
  }

  .tariffsSlider {
    position: relative;
    padding: 0 50px;
    margin-top: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;

    @media (max-width: 768px) {
      padding: 0;
    }

    .swiper {
      &__item {
        height: 100%;
        z-index: 10;
        padding: 0 100px;

        @media (max-width: 768px) {
          padding: 0;
        }

        .shadow_left {
          width: 96%;
          height: 70%;
          position: absolute;
          background: $color-light;
          top: 14%;
          left: 2%;
          z-index: 1;
          border-radius: 20px;
          box-shadow: 0 0 20px 0 #0000001A;
        }

        .content {
          background: $color-light-brown;
          border-radius: 20px;
          padding: 24px;
          position: relative;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__h3 {
            color: $color-brown;
            text-align: center;
          }
          &__description {
            display: flex;
            flex-direction: column;

            .questionTitle {
              margin-top: 24px;
              color: $color-brown;
            }

            .answerText {
              position: relative;
              margin-top: 12px;
              padding-left: 30px;

              &:before {
                position: absolute;
                content: '';
                background: url('../../../../../assets/images/icons/arrow-check.svg');
                width: 24px;
                height: 24px;
                left: 0;
              }
            }
          }

          &__priceContainer {
            margin-top: 10px;
            margin-bottom: 5px;

            &__item {
              margin: 0 10px;
              color: $color-brown;

              &_oldPrice {
                position: relative;
                color: #D1B4A6;

                &:after {
                  position: absolute;
                  content: '';
                  width: 110%;
                  background: #D1B4A6;
                  height: 2px;
                  left: -5%;
                  top: 45%;
                }
              }
            }
          }

          &__accessAllowedDays {
            color: $color-brown;
            margin-bottom: 20px;
          }
        }
      }
    }

    .prevArrow {
      position: absolute;
      background: url("../../../../../assets/images/icons/arrow-left_brown.svg");
      width: 32px;
      height: 32px;
      left: 0;
      top: 40%;
      cursor: pointer;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .nextArrow {
      position: absolute;
      background: url("../../../../../assets/images/icons/arrow-right_brown.svg");
      width: 32px;
      height: 32px;
      right: 0;
      top: 40%;
      cursor: pointer;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
