@import "../../../assets/styles/colors";

.modalSuccessful {
  background: $color-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
  padding: 24px;

  .img {
  }

  .title {
    margin-top: 24px;
  }

  .description {
    margin-top: 16px;
    text-align: center;
  }

  .btn {
    margin-top: 24px;
  }
}
