@import '../../../assets/styles/colors';

.header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  z-index: 100;

  @media (max-width: 768px) {
    height: 60px;
  }

  &_active {
    position: fixed;
  }

  .logo {
    width: 70px;
    height: 70px;
    z-index: 100;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }

    &__img {
      width: 100%;
    }
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;

    .menuButton {
      cursor: pointer;
      width: 18px;
      height: 12px;
      position: relative;
      z-index: 100;
      transition: transform 0.4s ease;

      @media (max-width: 768px) {
        width: 18px;
        height: 12px;
      }

      &.active {
        .menuButtonBurger {
          background-color: $color-black;
          transform: translate(0, 0) rotate(45deg);

          &:before, &:after {
            background-color: $color-black;
            transform: translate(0, 0) rotate(90deg);
          }
        }

      }

      .menuButtonBurger {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 2px;
        background-color: $color-black;
        transition: transform 0.4s ease;

        &:before, &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%; /* Ширина ліній */
          height: 2px; /* Висота ліній */
          background-color: $color-black;
          transition: transform 0.4s ease;
        }

        &:before {
          transform: translateY(-6px);
        }

        &:after {
          transform: translateY(6px);
        }
      }
    }

    .navMobile {
      background: $color-bg;
      position: fixed;
      z-index: 10;
      width: 100%;
      height: 100vh;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: -9999px;
      transition: left .4s;

      &.active {
        left: 0;
      }

      .navLinks {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &__item {
          cursor: pointer;
          text-decoration: none;
          margin-bottom: 32px;
          color: $color-black;

          @media (max-width: 768px) {
            display: flex;
          }
        }
      }

      .footer {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }

    .avatarStub {
      line-height: 0;
      z-index: 100;

      &__img {
        width: 56px;
        height: 56px;
        margin-left: 24px;
        border-radius: 50%;
        object-fit: cover;

        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

