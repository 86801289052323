.downloadFile {
  margin-top: 32px;

  h3 {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: bold;
  }

  .fileContainer {
    display: flex;
    align-items: center;
  }

  .iconWrapper {
    margin-right: 16px;
    width: 100px;
    height: 100px;
    background-color: #e6ded9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fileIcon {
    width: 48px;
    height: 48px;
  }

  .fileName {
    margin-bottom: 8px;
  }

  .downloadLink {
    color: #8a5933;
    text-decoration: underline;
  }
}
