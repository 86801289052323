@import '../../../assets/styles/colors';

.posterDetailsPage {
  padding-top: 140px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 80px;
  }

  &__link{
    text-decoration: none;
    color: $color-black;
  }

  &__title {
    margin-bottom: 16px;
    margin-left: 32px;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      background: url("../../images/icons/arrow-left.svg");
      position: absolute;
      left: -32px;
      width: 24px;
      height: 24px;
      top: 6px;

      @media (max-width: 768px) {
        top: 1px;
      }
    }
  }

  &__content {
    padding-left: 135px;
    padding-right: 135px;

    @media (max-width: 1440px){
      padding-left: 80px;
      padding-right: 80px;
    }

    @media (max-width: 1024px){
      padding-left: 24px;
      padding-right: 24px;
    }
    @media (max-width: 768px) {
      padding: 0;
    }

    .player {}

    .descriptionContainer {
      &__title {
        margin-top: 24px;
        font-size: 20px;
        font-weight: bold;
      }

      &__description {
        margin-top: 16px;
      }
    }
  }
}
