@import "../../../assets/styles/colors";
@import "../../../assets/styles/fonts";

.modalContent {
  background: $color-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
  padding: 24px;

  .description {
    margin-top: 16px;
    max-width: 404px;
    text-align: center;
  }

  .btn {
    margin-top: 24px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: $weight-bold;
  }
}
