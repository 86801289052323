.img {
  width: 80px;
  height: 80px;
  background-size: cover;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 16px;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    background: url("../../images/icons/edit.svg");
    width: 24px;
    height: 24px;
    top: 0;
    right: 0;
  }
}
