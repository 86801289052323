@import 'colors';
@import 'fonts';

.btn-brown {
  background-color: $color-brown;
  color: $color-white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: $font-montserrat;
  font-size: 14px;
  font-weight: $weight-bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: max-content;

  &:hover {
    background-color: darken($color-brown, 10%);
  }

  &:active {
    background-color: darken($color-brown, 20%);
  }
}

.btn-transparent {
  background-color: inherit;
  color: $color-brown;
  border: solid 1px $color-brown;
  font-size: 12px;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: $font-montserrat;
  font-weight: $weight-medium;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: max-content;
}
