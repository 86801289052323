@import "../../../assets/styles/colors";

.tariffsSliderContainer {
  .top {
    position: relative;

    .prevArrow {
      top: 0;
      position: absolute;
      background: url("../../../assets/images/icons/arrow-left_brown.svg");
      width: 32px;
      height: 32px;
      right: 40px;
      cursor: pointer;

      &.inactive {
        opacity: 20%;
      }
    }

    .nextArrow {
      top: 0;
      position: absolute;
      background: url("../../../assets/images/icons/arrow-right_brown.svg");
      width: 32px;
      height: 32px;
      right: 0;
      cursor: pointer;

      &.inactive {
        opacity: 20%;
      }
    }
  }
}


.currentTariff {
  margin-top: 24px;
  width: 100%;
  background: $color-light-brown;
  padding: 24px;
  border-radius: 20px;
  position: relative;

  &__title {
    color: $color-brown;
    margin-bottom: 24px;
  }

  &__cancelTariff {
    position: absolute;
    top: 16px;
    right: 24px;
  }

  .questionTitle {
    color: $color-brown;
    margin-bottom: 20px;
  }

  .answerText {
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background: url('../../../assets/images/icons/arrow-check.svg');
      width: 24px;
      height: 24px;
      left: 0;
    }
  }

  &__cancelled {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    border-top: solid 1px $color-brown;

    &__title {
      color: $color-brown;
      margin-top: 24px;
    }

    &__time {
      color: $color-brown;
      margin-top: 16px;
    }
  }

  &__paymentDetails {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    border-top: solid 1px $color-brown;
    flex-wrap: wrap;

    .block_one {
      margin-top: 24px;
      padding-right: 24px;

      .title {
        color: $color-brown;
      }

      .price {
        margin-top: 16px;
        color: $color-brown;
      }
    }

    .block_two {
      margin-top: 24px;

      .time {
        margin-bottom: 16px;
        color: $color-brown;
      }

      .checkbox {

      }
    }

    .block_three {
      width: 100%;
      margin-top: 24px;
    }
  }
}
