@import url('https://fonts.googleapis.com/css2?family=Epilogue&family=Raleway:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue&family=Montserrat:wght@400;500;700;900&family=Raleway:wght@400;500;700&display=swap');

// Define font family variables
$font-montserrat: 'Montserrat', sans-serif;
$font-raleway: 'Raleway', sans-serif;

// Define font weight variables
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;
$weight-black: 900;

// Montserrat Styles
.montserrat-54-black {
  font-family: $font-montserrat;
  font-size: 54px;
  font-weight: $weight-black;
  line-height: 66px;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
}

.montserrat-36-black {
  font-family: $font-montserrat;
  font-size: 36px;
  font-weight: $weight-black;
  line-height: 44px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.raleway-32-regular {
  font-family: $font-raleway;
  font-size: 32px;
  font-weight: $weight-black;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.raleway-24-regular {
  font-family: $font-raleway;
  font-size: 24px;
  font-weight: $weight-regular;
  line-height: 28px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
}

.raleway-24-bold {
  font-family: $font-raleway;
  font-size: 24px;
  font-weight: $weight-bold;
  line-height: 28px;
}

.raleway-16-regular {
  font-family: $font-raleway;
  font-size: 16px;
  font-weight: $weight-regular;
  line-height: 18px;
}

.raleway-16-bold {
  font-family: $font-raleway;
  font-size: 16px;
  font-weight: $weight-bold;
  line-height: 18px;
}

.raleway-12-medium {
  font-family: $font-raleway;
  font-size: 12px;
  font-weight: $weight-medium;
  line-height: 14px;
}
