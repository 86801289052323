@import "./src/assets/styles/colors";

.footerContainer {
  width: 100%;
  border-top: solid 1px $color-border;
  display: flex;
  justify-content: space-between;
  min-height: 72px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .socialMedia {
    display: flex;
    align-items: center;

    .feedback {
      margin-right: 8px;
      @media (max-width: 768px) {
        display: none;
      }
    }

    img {
      padding: 8px;
    }
  }

  .policy {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;

      &__text {
        margin-left: 0;
      }
    }

    &__text {
      opacity: 50%;
      margin-left: 24px;

      @media (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 8px;
      }
    }
  }
}
