@import '../../../assets/styles/colors';

:global(.swiper-pagination-bullet-active) {
  background: $color-brown !important;
}

.homePage {
  .heroSection {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    video {
      position: absolute;
      top: 100%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: 115%;
      z-index: -1;
      transform: translate(-50%, -100%);
      background-size: cover;
    }

    @media (max-width: 1400px) {
      video {
        height: 100%;
      }
    }

    &__h1 {
      z-index: 2;
      color: $color-light-brown;
      max-width: 760px;
    }

    &__subtitle {
      z-index: 2;
      margin-top: 16px;
      color: $color-light-brown;
    }
  }

  .aboutMeSection {
    background: no-repeat right/cover url("../../images/background/border-dashed-ellipse-bg.svg");
    background-size: 60%;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;

    .img {
      width: 50%;
    }

    .description {
      position: relative;
      height: max-content;
      margin: auto auto auto 30px;
      max-width: 460px;
      display: flex;
      flex-direction: column;

      &__h2 {
        position: absolute;
        max-width: 370px;
        left: -120px;
        top: -100px;
      }

      .img {
        margin-top: 24px;
        width: 207px;
        height: 76px;

        @media (max-width: 768px) {
          width: 116px;
          height: 43px;
        }
      }
    }

    @media (max-width: 768px) {
      background: none;
      flex-direction: column;
      align-items: center;

      .img {
        width: 100%;
        max-width: 345px;
      }

      .description {
        display: flex;
        flex-direction: column;
        margin: 40px 0 0 0;

        &__h2 {
          margin-bottom: 16px;
          position: relative;
          left: auto;
          top: auto;
          max-width: max-content;
        }
      }
    }
  }

  .motivationSection {
    position: relative;
    background-color: $color-light-brown;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
      content: '';
      background: url("../../images/background/border-dashed-dotted-bg.svg") no-repeat center;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;

      @media (max-width: 768px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__h2 {
      font-size: 96px;
      font-weight: 900;
      color: $color-brown;
      text-align: center;
      line-height: 117px;

      @media (max-width: 768px) {
        font-size: 36px;
        line-height: 44px;
      }
    }

    .reasons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      &__item {
        padding: 24px 24px 40px 24px;
        border-radius: 30px;
        background-color: $color-white;
        max-width: 370px;
        margin-bottom: 24px;
        z-index: 2;

        .img {
          display: flex;
          background-color: $color-light-brown;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          padding: 10px;
          margin-bottom: 16px;
        }
      }

      .col {
        display: flex;
        flex-direction: column;
        margin: 0 12px;

        @media (max-width: 768px) {
          margin: 0;
        }
      }
    }

    .img {
      margin-top: 8px;
      width: 448px;
      height: 76px;

      @media (max-width: 768px) {
        width: 252px;
        height: 43px;
      }
    }
  }

  .youCanWithMeSection {
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 1024px) {
      padding-top: 60px;
    }

    @media (max-width: 768px) {
      padding-bottom: 40px;
    }

    .h2 {
      max-width: 390px;
    }

    .description {
      margin: 80px auto auto auto;
      display: flex;
      position: relative;
      padding-right: 300px;
      width: max-content;

      @media (max-width: 1024px) {
        margin: 30px auto auto auto;
        padding-right: 0;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .imgContainer {
        position: relative;
        max-width: 620px;

        @media (max-width: 1280px) {
          max-width: 480px;
        }

        :after {
          content: "";
          background: url("../../images/background/border-dashed-circle-bg.svg") no-repeat;
          top: -10%;
          left: 0;
          position: absolute;
          z-index: 1;
          width: 120%;
          height: 120%;
          background-size: 90%;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        :before {
          content: "";
          background: url("../../images/background/points-semicircle-bg.svg") no-repeat;
          left: 82%;
          position: absolute;
          z-index: 1;
          width: 30%;
          height: 120%;
          background-size: 90%;
          top: -2%;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        .img {
          width: 100%;
          height: 100%;
        }

        .backgroundImg {
          top: 30px;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: $color-light-brown;
          border-radius: 50%;
          z-index: -1;


          @media (max-width: 1024px) {
            top: 0;
            left: 0;
          }
        }
      }

      .subtitles {
        position: absolute;
        right: 20px;
        top: 10px;

        @media (max-width: 1024px) {
          position: relative;
          right: 0;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 30px;
        }

        &__item {
          margin-bottom: 62px;
          position: relative;
          max-width: 300px;

          @media (max-width: 1280px) {
            margin-bottom: 36px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          @media (max-width: 1024px) {
            max-width: 410px;

            &:after {
              content: "";
              width: 10px;
              height: 10px;
              background: $color-brown;
              border-radius: 50%;
              position: absolute;
              left: -20px;
              top: 40%;
            }
          }
        }

        :nth-child(2) {
          left: 100px;

          @media (max-width: 1024px) {
            left: 0;
          }
        }

        :nth-child(3) {
          left: 130px;

          @media (max-width: 1024px) {
            left: 0;
          }
        }

        :nth-child(4) {
          left: 130px;

          @media (max-width: 1024px) {
            left: 0;
          }
        }

        :nth-child(5) {
          left: 100px;

          @media (max-width: 1024px) {
            left: 0;
          }
        }
      }
    }

  }

  .exercisesVideoSection {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 120px;

    @media (max-width: 1024px) {
      padding-top: 60px;
    }

    @media (max-width: 768px) {
      padding-bottom: 20px;
    }

    &__h2 {
      max-width: 500px;
      position: relative;
      margin-bottom: 60px;

      @media (max-width: 768px) {
        margin-bottom: 30px;
      }

      &:after {
        content: '';
        position: absolute;
        background: url("../../images/icons/arrow-down.svg");
        width: 100px;
        height: 80px;
        left: -150px;
        top: 40px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &__videoContainer {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      margin: 0 auto;

      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 20px;
      }
    }

    .img {
      margin-top: 50px;
      width: 304px;
      height: 76px;

      @media (max-width: 768px) {
        width: 171px;
        height: 43px;
      }
    }
  }

  .studentsFeedbackSection {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    padding-top: 60px;
    background: $color-light-brown;

    @media (max-width: 768px) {
      padding-top: 30px;
    }

    &__h2 {
      margin: 0 auto;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }

    .swiperContainer {
      height: max-content;
      position: relative;
      padding: 0 50px;

      @media (max-width: 768px) {
        padding: 0;
      }

      .swiper {
        height: 100%;
        padding-bottom: 50px;

        @media (max-width: 768px) {
          padding-bottom: 40px;
        }

        &__item {
          padding: 24px;
          background-color: $color-white;
          border-radius: 20px;

          .userInfo {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .img {
              width: 48px;
              height: 48px;
              margin-right: 16px;
            }

            .name {

            }
          }

          .question {
            margin-top: 16px;
          }

          .answer {
            margin-top: 8px;
          }
        }
      }

      .prevArrow {
        position: absolute;
        background: url("../../../assets/images/icons/arrow-left_brown.svg");
        width: 32px;
        height: 32px;
        left: 0;
        top: 40%;
        cursor: pointer;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .nextArrow {
        position: absolute;
        background: url("../../../assets/images/icons/arrow-right_brown.svg");
        width: 32px;
        height: 32px;
        right: 0;
        top: 40%;
        cursor: pointer;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .resultsSection {
    background: $color-light-brown;
    padding-bottom: 60px;

    @media (max-width: 768px) {
      padding-bottom: 30px;
    }

    &__h2 {
      text-align: center;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }

    .swiperContainer {
      background-color: $color-white;
      padding: 20px;
      height: 100%;
      border-radius: 20px;

      .swiper {
        height: 100%;

        &__item {
          @media (max-width: 768px) {
            height: 160px;
            width: 160px;
          }

          .img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .tariffsSection {
    padding-top: 120px;

    @media (max-width: 768px) {
      padding-top: 60px;
    }

    &__h2 {
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .popularQuestionsSection {
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__h2 {
      width: 30%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 24px;
      }

      .img {
        margin-top: 32px;
        width: 388px;
        height: 76px;

        @media (max-width: 768px) {
          width: 218px;
          height: 43px;
        }
      }
    }
  }

  .feedbackSection {
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;

    &__content {
      width: 30%;
      display: flex;
      flex-direction: column;

      &__h2 {
        margin-bottom: 24px;
      }

      &__description {

      }
    }

    &__form {
      width: 50%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 60px;

      &__content {
        width: 100%;
      }

      &__form {
        margin-top: 24px;
        width: 100%;
      }
    }
  }
}
