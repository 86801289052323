@import "../../../assets/styles/colors";
@import "../../../assets/styles/fonts";

.settingsPage {
  padding-top: 140px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 768px) {
    padding-top: 80px;
  }

  &__h2 {
    margin-bottom: 24px;
  }

  .tabs {
    display: flex;
    width: 100%;
    border-bottom: solid $color-border 1px;
    margin-bottom: 24px;

    .swiper {
      margin-left: 0;
      width: 100%;

      &__item {
        width: max-content !important;
        height: max-content;
        z-index: 10;
      }
    }

    .tab {
      padding: 0 3px 24px 3px;
      cursor: pointer;
      color: $color-title;
      height: 100%;
      margin-right: 24px;

      &__active {
        color: $color-brown;
        border-bottom: solid $color-brown 1px;
        font-weight: $weight-bold;
        transition: all .2s;
      }
    }
  }

  .profile {
    .form {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;

      &__inputContainer {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        max-width: 370px;
        width: 40%;
        margin-right: 10%;
        position: relative;

        @media (max-width: 768px) {
          width: 100%;
          margin-right: 0;
        }


        &__label {
          top: -8px;
          color: $color-black;
          opacity: 40%;
          position: absolute;
        }
      }
    }
  }

  .changePassword {
    .form {
      display: flex;
      flex-direction: column;

      &__inputContainer {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        max-width: 370px;
        position: relative;


        &__label {
          top: -8px;
          color: $color-black;
          opacity: 40%;
          position: absolute;
        }
      }

      .btn {
        margin-top: 24px;
      }
    }
  }

  .tariff {
    .currentTariffContainer {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      &_left {
        width: 50%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &_right {
        width: 40%;

        @media (max-width: 768px) {
          width: 100%;
        }

        .title {
          margin-bottom: 16px;
        }

        .block_one {
          margin-bottom: 60px;

          @media (max-width: 768px) {
            margin-top: 24px;
          }

          .description__price {
            color: $color-brown;
          }
        }

        .block_two {
          @media (max-width: 768px) {
            margin-top: 24px;
          }
        }

        .btn {
          margin-top: 24px;
        }
      }
    }

    .currentTariffEnded {
      .title {
        margin-bottom: 24px;
      }

      .description {
        margin-bottom: 24px;
        max-width: 440px;
      }
    }

    .addTariffContainer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      margin-top: 60px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      &__goBack {
        cursor: pointer;
        position: absolute;
        top: -50px;
        left: 40px;

        &:before {
          content: '';
          background: url('../../../assets/images/icons/arrow-left_black.svg');
          position: absolute;
          width: 32px;
          height: 32px;
          left: -40px;
          top: -8px;
        }
      }

      .title {

      }

      //&_left {
      //  width: 50%;
      //
      //  @media (max-width: 768px) {
      //    width: 100%;
      //    order: 2;
      //    margin-top: 24px;
      //  }
      //}
      //
      //&_right {
      //  width: 40%;
      //
      //  @media (max-width: 768px) {
      //    width: 100%;
      //  }
      //}
    }
  }
}
