$color-brown: #8E6450;
$color-light-brown: #ECDFD6;
$color-light: #F9F5F3;
$color-pink: #E28C88;
$color-rose: #F8D2D0;
$color-black: #000000;
$color-title: #919191;
$color-border: #DADADA;
$color-bg: #F2F2F2;
$color-white: #FFFFFF;
$color-green: #3BA16A;
$color-red: #E55555;
